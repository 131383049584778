<script>

import moment from 'moment';
export default {
  computed: {
    currentPackage() {
        return this.$store.getters["packages/paymentHistory"]
    },
    nama() {
        return this.$store.getters['user/fullname']
    },
    email() {
        return this.$store.getters['user/email']
    },
    localtime(){
      return moment().format('hh:mm A')
    }
  },
  created () {
    if(this.$store.getters['user/email'] != ""){
      this.$store.dispatch('packages/getPaymentHistory')
    }
  },
  methods:{
    close(){
        this.$root.$emit('bv::hide::modal', 'profile');
    },
  }
};
</script>
<template>
    <b-modal id="profile"  no-fade modal-sm  content-class="shadow" no-stacking :hide-header="true" :hide-footer="true"  centered >
        <div class="d-flex justify-content-end align-items-center">
            <span class="cursor-pointer px-2" @click="close()">
                <img src="@/assets/images/icon/Close.png" alt width="100%" />
            </span>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center py-4">
          <b-avatar variant="primary" :text="nama.charAt(0).toUpperCase()" class="mb-3" size="5rem"></b-avatar>
          <h1 class="default-text-style bold-normal">{{nama}}</h1>
          <span class="d-xl-inline-block mx-2 default-text-style font-size-12"> <i class="bx bxs-circle text-green"></i> <span class="text-muted letter05"> Active</span></span>
        </div>
        <div class="px-3">
          <p class="default-text-style bold-normal">Set yourself as <span class="text-blue">away</span></p>
          <div class="d-flex justify-content-between align-items-center">
            <p class="default-text-style bold-normal">Personal Details</p>
            <a href="javascript:void(0);" v-b-modal.editProfile class="default-text-style bold-normal"><span class="text-blue">Edit</span></a>
          </div>
          <hr class="mt-1">
          <p class="default-text-style bold-normal letter05 mb-0 pb-0"><span class="text-muted font-size-12">Your Plan</span></p>
          <p class="default-text-style bold-normal mt-0 pt-0" v-if="currentPackage.length <= 0">Free</p>
          <p class="default-text-style bold-normal mt-0 pt-0" v-if="currentPackage.length >= 1">{{currentPackage ? currentPackage[0].package.name_package : 'Free'}}</p>
          <p class="default-text-style bold-normal letter05 mb-0 pb-0"><span class="text-muted font-size-12">Email</span></p>
          <p class="default-text-style bold-normal mt-0 pt-0">{{email}}</p>
          <p class="default-text-style bold-normal letter05 mt-3 mb-0 pb-0"><span class="text-muted font-size-12">Local Time</span></p>
          <p class="default-text-style bold-normal mt-0 pt-0">{{localtime}}</p>
        </div>
    </b-modal>
</template>