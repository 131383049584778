<script>

import moment from 'moment';
export default {
    data() {
        return {
        recent : "",
        recentType : "password",
        newPass : "",
        newPassType : "password",
        confirmPass : "",
        confirmPassType : "password",
        }
    },
    computed: {
        firstname(){
            return this.$store.getters['user/firstName'];
        },
        lastname(){
            return this.$store.getters['user/lastName'];
        },
        loading(){
            return this.$store.getters['user/loading'];
        },
        nama() {
            return this.$store.getters['user/firstName']
        },
        email() {
            return this.$store.getters['user/email']
        },
        localtime(){
            return moment().format('hh:mm A')
        },
        message() {
            return this.$store.getters['error/message']
        },
        success() {
            return this.$store.getters['error/success']
        },
        error() {
            return this.$store.getters['error/error']
        },
    },
    mounted(){
    },
    methods:{
        close(){
            this.$root.$emit('bv::hide::modal', 'password');
        },
        submit(){
            if(this.newPass != this.confirmPass){
                this.$store.commit("error/SET_MESSAGE","Confirm Password does not match");
                this.$store.commit("error/SET_ERROR",true);
            }else{
                this.$store.dispatch("user/PasswordEdit",
                {
                    new_password: this.newPass,
                    old_password: this.recent
                });
            }
            this.$root.$emit('bv::hide::modal', 'password');
        },
        closeAlertE(){
            this.$store.commit("error/SET_MESSAGE","");
            this.$store.commit("error/SET_ERROR",false);
        },
    }
};
</script>
<template>
    <b-modal id="password"  no-fade modal-sm  content-class="shadow" :hide-header="true" :hide-footer="true"  centered >
        <div class="d-flex justify-content-between align-items-center m-3">
            <span class="default-text-style bold-normal text-dark">Edit Password 🔒</span>
            <span class="cursor-pointer px-2" @click="close()">
                <img src="@/assets/images/icon/Close.png" alt width="100%" />
            </span>
        </div>
            <div class="form mx-3">
                <b-alert  variant="danger" show  v-if="error" class="d-flex justify-content-between align-items-center">
                    <span class="px-4">{{message}}</span>
                    <span class="cursor-pointer px-2" @click="closeAlertE()">
                        <i class="bx bx-x font-size-16"></i>
                    </span>
                </b-alert>
                <b-form-group id="input-group-4"  class="py-2 mt-2" label-class="label-input float-left"  label-for="input-2">
                    <b-input-group>
                        <b-form-input
                            id="input-4"
                            v-model="recent"
                            :type="recentType"
                            class="login-input-style border-right-none "
                            placeholder="Current Password"
                            >
                        </b-form-input>
                        <b-input-group-append>
                            <b-button  @click="()=>{recentType = (recentType == 'password') ?  'text' : 'password' }"  class="btn-on-input">
                            <i :class="(recentType != 'password') ?  'far fa-eye' : 'far fa-eye-slash'"></i>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <b-form-group id="newPass" class="py-2 mt-2" label-class="label-input float-left"  >
                    <b-input-group>
                        <b-form-input
                            id="newPassP"
                            v-model="newPass"
                            :type="newPassType"
                            class="login-input-style border-right-none "
                            placeholder="New Password"
                            >
                        </b-form-input>
                        <b-input-group-append>
                            <b-button   @click="()=>{newPassType = (newPassType == 'password') ?  'text' : 'password' }"  class="btn-on-input">
                            <i :class="(newPassType != 'password') ?  'far fa-eye' : 'far fa-eye-slash'"></i>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <b-form-group id="confirmPass"  class="py-2 mt-2" label-class="label-input float-left"  >
                    <b-input-group>
                        <b-form-input
                            id="confirmPassP"
                            v-model="confirmPass"
                            :type="confirmPassType"
                            class="login-input-style border-right-none"
                            placeholder="Confirm New Password"
                            >
                        </b-form-input>
                        <b-input-group-append>
                            <b-button   @click="()=>{confirmPassType = (confirmPassType == 'password') ?  'text' : 'password' }"  class="btn-on-input">
                            <i :class="(confirmPassType != 'password') ?  'far fa-eye' : 'far fa-eye-slash'"></i>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
              <div class="mt-4 pt-2 mb-2 d-flex justify-content-end align-items-center">
                   <router-link tag="a" to="/" class="default-text-style bold-normal mx-4"><span class="text-blue">Cancel</span></router-link>
                   <button class="btn base-btn btn-storage ml-4" @click="submit">
                     <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>
                     <span class="default-text-style">Edit Password</span>
                    </button>
              </div>
            </div>
    </b-modal>
</template>