<script>
export default {
    data() {
        return {
            recent : "",
            newFirstName : "",
            stateFirstName : true,
            invalidFirstName : "Please Fill First Name Field !",
            newLastName : "",
            stateLastName : true,
            invalidLastName : "Please Fill Last Name Field !",
            newEmail : "",
            stateEmail : true,
            invalidEmail : "Please Fill Email Field"
        }
    },
    computed: {
        firstname(){
            return this.$store.getters['user/firstName'];
        },
        lastname(){
            return this.$store.getters['user/lastName'];
        },
        loading(){
            return this.$store.getters['user/loading'];
        },
        nama() {
            return this.$store.getters['user/firstName']
        },
        email() {
            return this.$store.getters['user/email']
        },
        message() {
            return this.$store.getters['error/message']
        },
        success() {
            return this.$store.getters['error/success']
        },
        error() {
            return this.$store.getters['error/error']
        },
    },
    mounted(){
        this.reset();
    },
    methods:{
        deleteAccount(){
            this.$root.$emit('bv::hide::modal', 'editProfile')
            this.$root.$emit('bv::show::modal', 'delete-account')
        },
        close(){
            this.reset();
            this.$root.$emit('bv::hide::modal', 'editProfile');
        },
        reset(){
            this.newFirstName = this.firstname;
            this.stateFirstName = true;
            this.newLastName = this.lastname;
            this.stateLastName = true;
            this.newEmail = this.email;
            this.stateEmail = true;
        },
        submit(){
            if(this.newFirstName == ""){
                this.stateFirstName = false;
                return false
            }
            if(this.newLastName == ""){
                this.stateLastName = false;
                return false
            }
            if(this.newEmail == ""){
                this.stateEmail = false;
                return false
            }
            this.$store.dispatch("user/editPassword",
            {
                firstname: this.newFirstName,
                lastname: this.newLastName,
                email: this.newEmail,
            }).then(()=>{
                this.reset();
                this.$root.$emit('bv::hide::modal', 'editProfile');
                return true;
            });

        },
        closeAlertE(){
            this.$store.commit("error/SET_MESSAGE","");
            this.$store.commit("error/SET_ERROR",false);
        },
    }
};
</script>
<style scoped>
.btn-del{
    background-color: #FBE9E9;
    color: #D42020;
    font-size: 16px;
}
</style>
<template>
    <b-modal id="editProfile"  no-fade modal-sm  content-class="shadow" :hide-header="true" :hide-footer="true"  centered >
        <div class="d-flex justify-content-between align-items-center m-3">
            <span class="default-text-style bold-normal text-dark">Personal Details ✏️</span>
            <span class="cursor-pointer px-2" @click="close()">
                <img src="@/assets/images/icon/Close.png" alt width="100%" />
            </span>
        </div>
        <div class="mx-3 my-4 pt-4">
            <b-alert  variant="danger" show  v-if="error" class="d-flex justify-content-between align-items-center">
                <span class="px-4">{{message}}</span>
                <span class="cursor-pointer px-2" @click="closeAlertE()">
                    <i class="bx bx-x font-size-16"></i>
                </span>
            </b-alert>
            <b-form @submit.prevent="submit()">
                <div class="row">
                    <div class="col-md-6">
                        <b-form-group id="firstnamep" :invalid-feedback="invalidFirstName" :state="stateFirstName">
                            <b-form-input id="firstname"  class="login-input-style" placeholder="First Name" v-model="newFirstName"  trim></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-md-6">
                        <b-form-group id="lastnamep" :invalid-feedback="invalidLastName" :state="stateLastName">
                            <b-form-input id="lastname"  class="login-input-style" placeholder="Last Name" v-model="newLastName" trim></b-form-input>
                        </b-form-group>
                    </div>
                </div>
                <b-form-group id="emailp" :invalid-feedback="invalidEmail" :state="stateEmail">
                    <b-form-input id="email" disabled readonly type="email"  class="login-input-style" placeholder="Email" v-model="newEmail" trim></b-form-input>
                </b-form-group>
            </b-form>
            <div class="row">
                <div class="col-md-6">
                    <div class="mt-4 pt-4 d-flex justify-content-start align-items-center">
                        <button @click="deleteAccount" class="btn btn-del">Delete Account</button>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mt-4 pt-4 d-flex justify-content-end align-items-center">
                        <a href="javascript:void(0);" @click="close()" class="default-text-style bold-normal mx-4"><span class="text-blue">Cancel</span></a>
                        <button type="submit" class="btn base-btn btn-storage ml-4" @click="submit()">
                            <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>
                            <span class="default-text-style">Save</span>
                            </button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>